body{
    overflow-x: hidden;
    margin: 0;
    .app{
        transition: all 0.1s;

        padding: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;

        position: relative;

        gap: 50px;

        /* font-size: 1.6vh; */
        font-family: RedHat;
        color: var(--second-color);


        background: url("../images/bg.png");

        /* background-image: url("./images/bg.png");
        background-size: 2000px;
        background-position: center; */

        &.dark{
            background-color: var(--dark);
        }
        @media (max-width : 650px) {
            font-size: 0.8em;
        }

    }
    
    

    
}