@keyframes fadein {
    from {
        transform: translateY(-130px);
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

.main-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100vh;
    min-height: 700px;

    gap: 25px;

    margin-bottom: -50px;
    em{
        font-weight: bold;
        font-style: normal;
    }

    &>*{
        animation: fadein 1s forwards;
        opacity: 0;


    }
    &>*:nth-child(1){animation-delay: 200ms;}
    &>*:nth-child(2){animation-delay: 400ms;}
    &>*:nth-child(3){animation-delay: 600ms;}

    .tittle{
        

        width: 100%;
        height: 22%;
        max-height: 220px;
        flex: 1;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        
        .name{

            
            height: 100%;
            text-align: end;
            font-family: RedHatBold;
            font-size: 4vh;
            border-right: 5px solid var(--main-color);
            padding-right: 20px;
            
            display: flex;
            flex-direction: column;
            justify-content: center;  
            @media (max-height : 550px){
                font-size: 1.4em;
            }
            @media (min-height : 1000px){
                font-size: 2.2em;
            }          
            h1{
                margin: 0;
            }
            
        }

        img{
            height: 100%;
        }

        
    }

    p{
        font-size: larger;
        text-align: center;
    }

    .buttons{
        /* width: 100%; */
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 50px;

        /* font-size: larger; */
    }

    @media (max-width : 650px) {
        .tittle{
            flex-direction: column-reverse;
            height: 50%;
            gap: 10px;
            max-height: 350px;

            .name{
                border-right: none;
                text-align: center;
                padding: 0;
                font-size: 1.5em;
            }
        }

        p{
            font-size: 1.5em;
        }
        
        .buttons{
            flex-direction: column;
            gap: 15px;
        }
    }

    
}