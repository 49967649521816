.about{
    width: calc(100% - 160px);
    max-width: 700px;
    background: linear-gradient(var(--main-color), var(--main-fade));
    color: white;
    padding: 20px 80px;
    text-align: center;
    font-size: 1.1em;

    border-radius: 35px;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width : 650px) {
        width: calc(100% - 50px);
        padding: 20px 25px;
    }

    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.164);

    .moi{
        background-image: url("../images/moi.png");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        height: 170px;
        width: 170px;

        border-radius: 50%;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.164);

        &::after{
            content: "";
            display: block;
            border-radius: 50%;
            width: 100%;
            height: 100%;
            background : linear-gradient(to top, rgba(41, 41, 41, 0.073), rgba(255, 255, 255, 0))
        }
    }

    h2{
        font-weight: normal;
    }

    em{
        font-style: normal;
        font-weight: bolder;
    }
}

.dark .about{
    color: var(--dark-light);
    background: var(--dark-green);
}