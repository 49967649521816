@keyframes fadein-header {
    from {
        transform: translateY(-70px);
        opacity: 0;
    }

    50%{
        opacity: 0;
    }

    to {
        transform: translateY(0px);
        opacity: 1;
    }
}

header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 5%;
    min-height: 50px;

    z-index: 100;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    

    ul{
        padding: 10px 30px;
        margin: 0;

        animation:  1.2s fadein-header ease-in-out;

        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 30px;

        
        backdrop-filter: blur(10px);
        background: var(--header-back);

        border-radius: 30px;

        li{
            cursor: pointer;
        
            transition: all .3s;
            color: var(--second-color);
            text-decoration: none;
            &:hover{
                color: var(--second-color-selected);  
            }

            &.selected{
                color: var(--main-color);
                &:hover{
                    color: var(--main-color-selected);  
                }
                
            }
        }
    }

    .dark-mode-button{
        position: absolute;
        right: 25px;
        width: 3vh;
        height: 3vh;

        @media (max-width : 650px) {
            position: fixed;
            bottom: 5px;
            right: 5px;
        }

        #sun{
            opacity: 0;
        }

        transition: transform 0.3s;
        &:hover{
            transform: scale(1.3);
            cursor: pointer;
        }

        img{
            position: absolute;

            transition: all 0.5s;

            width: 3vh;
            height: 3vh;
            
            
        }
    }
}

.dark {
    #sun{
        opacity: 1 !important;
    }

    #moon{
        opacity: 0 !important;
    }
}