.footer{
    font-family: RedhatLight;

    margin: 20px -25px -25px;
    width: calc(100% + 30px);

    background-color: var(--bg-varient);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 10px;
    gap: 10px;
    p{
        margin: 0;
    }

    img{
        height: 4em;
        width: auto;
    }
}