@font-face {
    font-family: Redhat;
    src: url('../fonts/RedHatText-Regular.ttf');
}

@font-face {
    font-family: RedhatBold;
    src: url('../fonts/RedHatText-Bold.ttf');
}

@font-face {
    font-family: RedhatLight;
    src: url('../fonts/RedHatText-Light.ttf');
}

.admin{
    font-family: Redhat;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    gap: 30px;

    margin: 10px;

    header{
        all: unset;

        padding: 20px 3%;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        background-color: #34568B;
        border: .5px solid black;
        color: white;

        h1{
            margin: 0;
        }

    }

    .connect-form{
        height: 50vh;
        min-height: 150px;
        width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        align-self: center;

        span{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        span, button{
            margin: 3px;
        }
    }

    .page{

        display: flex;
        flex-direction: column;
        gap: 20px;
        .panel{
            position: relative;
            &.pending:before{
                content : "Chargement";
                position: absolute;
                top: 0;
                left : 0;
                right : 0;
                bottom: 0;

                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                z-index: 3;
                background-color: rgba(0, 0, 0, 0.56);
                color: white;
            }

            .head{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                padding: 3px 3%;
                margin: 0;
                color: white;
                border-top: 0.5px solid black;
                border-left: 0.5px solid black;
                border-right: 0.5px solid black;
                background-color: #34568B;

                h2{
                    margin: 0;
                }
            }

            table{
                width: 100%;
                font-size: .9em;
                border-collapse: collapse;


                thead{
                    background-color: #34568B;
                    color: white;
                }
                tr{
                    td, th{
                        border: .5px solid black;
                        padding: 3px;
                        overflow: auto;

                        em{
                            font-size: 60%;
                            font-weight: lighter;
                        }

                        &.date{
                            width: 110px;
                            text-align: center;
                        }

                        &.message{
                            max-width: unset;
                            width: 60%;
                        }

                        &.desc{
                            max-width: unset;
                            width: 50%;
                        }

                        &.img{
                            &>div{
                                display: flex;
                                flex-direction: column;
                                img{
                                    max-width: 150px;
                                    &:hover{
                                        opacity: 0.8;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }

                        &.control{
                            .manager{
                                position: absolute;
                                top: 0;
                                right: 0;
                                left: 0;
                                bottom: 0;

                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                gap: 1vh;

                                z-index: 3;
                                background-color: rgba(0, 0, 0, 0.56);
                                color: white;


                                .images{
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    &>div{
                                        border: 1px solid white;
                                        padding: 3px;
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                    }
                                    img{
                                        max-width: 200px;
                                    }
                                }

                                .new{
                                    border: 1px solid white;
                                    padding: 3px;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                }
                            }
                        }

                        textarea{
                            resize: none;
                            margin: 0;
                            width: calc(100% - 6px);
                            min-height: 7em;
                            height: 100%;

                        }
                    }
                    &:nth-child(2n){
                        background-color: #e8e8e8;
                    }
                    &.viewed{
                        opacity: 0.4;
                    }


                }
            }
            h3{
                border-bottom: .5px solid black;
                border-left: .5px solid black;
                border-right: .5px solid black;

                background-color: #e8e8e8;

                margin: 0;
                padding: 10px 0;
                text-align: center;
            }
        }
    }
}